import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { UserLoginInfoDto } from '@shared/service-proxies/service-proxies';
import { User } from 'app/core/user/user.types';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { FuseConfigService } from '@fuse/services/config';
@Injectable({providedIn: 'root'})
export class UserService
{
    private _fuseConfigService = inject(FuseConfigService);
    private _httpClient = inject(HttpClient);
    private _user: ReplaySubject<UserLoginInfoDto> = new ReplaySubject<UserLoginInfoDto>(1);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: UserLoginInfoDto)
    {

        
        let userThemes = {};
        try {
            userThemes = JSON.parse(value.themeSettings ?? "{}");
        } catch (error) {
            console.error("Invalid JSON format in themeSettings:", error);
        }

        console.log('userThemes', userThemes);
        this._fuseConfigService.config = userThemes;
        console.log('this._fuseConfigService.config', this._fuseConfigService.config);


        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<UserLoginInfoDto>
    {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    get(): Observable<UserLoginInfoDto>
    {
        return this._httpClient.get<UserLoginInfoDto>('api/common/user').pipe(
            tap((user) =>
            {
                this._user.next(user);
            }),
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: UserLoginInfoDto): Observable<any>
    {
        return this._httpClient.patch<UserLoginInfoDto>('api/common/user', {user}).pipe(
            map((response) =>
            {
                this._user.next(response);
            }),
        );
    }
}
