import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/app.config';
import { RootModule } from '../src/root.module';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';

// Bootstrap the application
// bootstrapApplication(AppComponent, appConfig)
//     .catch(err => console.error(err));

// Bootstrap the module
const bootstrap = () => {
    return platformBrowserDynamic().bootstrapModule(RootModule);
};

if (environment.production) {
    enableProdMode();
}

if (environment.hmr) {
    if (module['hot']) {
        hmrBootstrap(module, bootstrap); // HMR enabled bootstrap
    } else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
} else {
    bootstrap(); // Regular bootstrap
}
