/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    },
    {
        id   : 'module.passengers',
        title: 'Passengers',
        type : 'basic',
        icon : 'heroicons_outline:identification',
        link : '/passengers/info'
    },
    {
        id   : 'module.bookings',
        title: 'Bookings',
        type : 'basic',
        icon : 'heroicons_outline:ticket',
        disabled: false,
        link : '/transactions/bookings'
    },
    {
        id   : 'module.accounting',
        title: 'Accounting',
        type : 'basic',
        icon : 'heroicons_outline:banknotes',
        disabled: false,
        link : '/accounting'
    },
    // {
    //     id   : 'module.reports',
    //     title: 'Reports',
    //     type : 'basic',
    //     icon : 'heroicons_outline:arrow-trending-up',
    //     disabled: true,
    //     link : '/pages/maintenance'
    // },
    // {
    //     id      : 'accounting',
    //     title   : 'Accounting',
    //     subtitle: 'Manage your finances',
    //     type    : 'group',
    //     icon    : 'heroicons_outline:banknotes',
    //     children: [
    //         {
    //             id   : 'module.reports.audit-logs',
    //             title: 'SOA',
    //             type : 'basic',
    //             icon : 'heroicons_outline:banknotes',
    //             link : '/reports/audit-logs'
    //         },

    //     ],
    // },
    {
        id      : 'reports',
        title   : 'Reports',
        subtitle: 'Unveiling Finances Trends, Analysis, and Recommendations',
        type    : 'group',
        icon    : 'heroicons_outline:arrow-trending-up',
        children: [
            // {
            //     id   : 'module.accounting',
            //     title: 'Accounting',
            //     type : 'basic',
            //     icon : 'heroicons_outline:banknotes',
            //     disabled: true,
            //     link : '/reports/audit-logs'
            // },
            {
                id   : 'module.reports.audit-logs',
                title: 'Audit Logs',
                type : 'basic',
                icon : 'heroicons_outline:document-magnifying-glass',
                link : '/reports/audit-logs'
            },

        ],
    },
    {
        id      : 'database',
        title   : 'Databases',
        subtitle: 'Manage your databases',
        type    : 'group',
        icon    : 'heroicons_outline:circle-stack',
        children: [
            {
                id   : 'module.database.passengers',
                title: 'Passengers',
                type : 'basic',
                icon : 'mat_outline:contacts',
                link : '/database/passengers'
            },
            {
                id   : 'module.database.agents',
                title: 'Agents',
                type : 'basic',
                icon : 'mat_outline:badge',
                disabled: false,
                link : '/database/agents'
            },
            {
                id   : 'module.database.suppliers',
                title: 'Suppliers',
                type : 'basic',
                icon : 'heroicons_outline:truck',
                disabled: false,
                link : '/database/suppliers'
            },
            {
                id   : 'module.database.packages',
                title: 'Packages',
                type : 'basic',
                icon : 'heroicons_outline:shopping-bag',
                disabled: false,
                link : '/database/packages'
            },
        ],
    },
    {
        id      : 'administration',
        title   : 'Administration Tools',
        subtitle: 'Manage this application',
        type    : 'group',
        icon    : 'heroicons_outline:shield-check',
        children: [

            {
                id   : 'administration.users',
                title: 'Users',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/administration/users',
            },
            {
                id   : 'administration.roles',
                title: 'Roles',
                type : 'basic',
                icon : 'heroicons_outline:lock-closed',
                link : '/administration/roles',
            },
            {
                id   : 'administration.appSettings',
                title: 'App Settings',
                type : 'basic',
                icon : 'heroicons_outline:cog',
                link : '/administration/app-settings',
            },
            {
                id   : 'administration.systemLogs',
                title: 'System Logs',
                type : 'basic',
                icon : 'heroicons_outline:clipboard-document-check',
                link : '/administration/system-logs',
            },
        ],
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard',
    },
    {
        id: 'module.passengers',
        title: 'Passengers',
        tooltip: 'Passengers',
        type: 'basic',
        icon: 'heroicons_outline:identification',
        link : '/transactions/bookings'
    },
    {
        id: 'module.bookings',
        title: 'Bookings',
        tooltip: 'Bookings',
        type: 'basic',
        icon: 'heroicons_outline:ticket',
        link : '/transactions/bookings'
    },
    {
        id   : 'module.accounting',
        title: 'Accounting',
        tooltip: 'Accounting',
        type : 'basic',
        icon : 'heroicons_outline:banknotes',
        disabled: false,
        link : '/accounting'
    },
    {
        id: 'reports',
        title: 'Reports',
        tooltip: 'Unveiling Finances Trends, Analysis, and Recommendations',
        type: 'aside',
        icon    : 'heroicons_outline:arrow-trending-up',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'database',
        title: 'Databases',
        tooltip: 'Databases',
        type: 'aside',
        icon: 'heroicons_outline:circle-stack',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'administration',
        title: 'Administration Tools',
        tooltip: 'Administration Tools',
        type: 'aside',
        icon: 'heroicons_outline:shield-check',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];


export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard',
    },
    {
        id: 'module.passengers',
        title: 'Passengers',
        tooltip: 'Passengers',
        type: 'basic',
        icon: 'heroicons_outline:identification',
        link : '/passengers/info'
    },
    {
        id: 'module.bookings',
        title: 'Bookings',
        tooltip: 'Bookings',
        type: 'basic',
        icon: 'heroicons_outline:ticket',
        link : '/transactions/bookings'
    },
    {
        id   : 'module.accounting',
        title: 'Accounting',
        tooltip: 'Accounting',
        type : 'basic',
        icon : 'heroicons_outline:banknotes',
        disabled: true,
        link : '/transactions/bookings'
    },
    {
        id: 'reports',
        title: 'Reports',
        tooltip: 'Unveiling Finances Trends, Analysis, and Recommendations',
        type: 'aside',
        icon    : 'heroicons_outline:arrow-trending-up',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'database',
        title: 'Databases',
        tooltip: 'Databases',
        type: 'aside',
        icon: 'heroicons_outline:circle-stack',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'administration',
        title: 'Admin',
        tooltip: 'Administration Tools',
        type: 'aside',
        icon: 'heroicons_outline:shield-check',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];
