import { Component, OnInit } from '@angular/core';
import { ToastNotificationService } from './toast-notification.service';
import { NgIf } from '@angular/common';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'toast-notification',
    templateUrl: './toast-notification.component.html',
    standalone: true,
    imports: [
        NgIf
    ],
    animations : fuseAnimations
})
export class ToastNotificationComponent implements OnInit {
    toastMessage = null; // The message to display
    showsToast = false; // Toggle to show/hide the component

    constructor(
        private _toastService: ToastNotificationService
    ) {
    }


    ngOnInit(): void {
        this._toastService.toastMessage$.subscribe((message) => {

            if (message) {
                this.toastMessage = message;
                this.showsToast = true;

                setTimeout(() => {
                    this.showsToast = false;
                }, 3000);
            }

        });
    }
}
