import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ToastNotificationService {
    private toastSubject = new BehaviorSubject<string>('');

    constructor() {}

    // Call this method to show a toast
    show(message: string) {
        this.toastSubject.next(message);
    }

    // Observable to subscribe to toast messages
    get toastMessage$() {
        return this.toastSubject.asObservable();
    }
}
