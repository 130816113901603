export class AppConsts {

    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'TravelM8Express'
    };

    static readonly authorization = {
        encryptedAuthTokenName: 'enc_auth_token'
    };

    static readonly settingsName = {
        bookingType: 'Booking type',
        bookingStatus: 'Booking status',
        documentServiceType: 'Document service type',
        embassy: 'Embassy',
        typeOfVisa: 'Visa assistance type',
        visaStatus: 'Visa status',
        ticketType: 'Ticket type',
        fitPackageType: 'FIT Package Type',
        visaRequirementChecklist: 'Visa requirement checklist',
        paymentStatus: 'Payment status',
    }
}
