import { filter as _filter, merge as _merge } from 'lodash-es';
import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { IStaticMethods } from 'preline/preline';

declare global {
    interface Window {
        HSStaticMethods: IStaticMethods;
    }
}
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    /**
     * Constructor
     */
    constructor(private router: Router)
    {

    }
    ngOnInit(): void {
        // this.router.events.subscribe((event: Event) => {
        //     if (event instanceof NavigationEnd) {
        //         setTimeout(() => {
        //             window.HSStaticMethods.autoInit();
        //         }, 100);
        //     }
        // });
    }
}
