import { NgModule, APP_INITIALIZER, LOCALE_ID, inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';

import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { AbpHttpInterceptor } from 'abp-ng2-module';

import { SharedModule } from 'shared/shared.module';
import { ServiceProxyModule } from 'shared/service-proxies/service-proxy.module';
import { RootRoutingModule } from './root-routing.module';
import { AppConsts } from 'shared/AppConsts';
import { API_BASE_URL } from 'shared/service-proxies/service-proxies';


import { AppInitializer } from './app-initializer';
import { AppComponent } from '@app/app.component';
import { PreloadAllModules, RouterOutlet, provideRouter, withInMemoryScrolling, withPreloading } from '@angular/router';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { appRoutes } from '@app/app.routes';
import { provideAuth } from '@app/core/auth/auth.provider';
import { provideIcons } from '@app/core/icons/icons.provider';
import { TranslocoHttpLoader } from '@app/core/transloco/transloco.http-loader';
import { mockApiServices } from '@app/mock-api';
import { provideFuse } from '@fuse';
import { provideTransloco, TranslocoService } from '@ngneat/transloco';
import { AppSessionService } from '@shared/session/app-session.service';
import { firstValueFrom } from 'rxjs';
import { provideToastr } from 'ngx-toastr';

export function getCurrentLanguage(): string {
    if (abp.localization.currentLanguage.name) {
        return abp.localization.currentLanguage.name;
    }

    // todo: Waiting for https://github.com/angular/angular/issues/31465 to be fixed.
    return 'en';
}

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        SharedModule.forRoot(),
        ModalModule.forRoot(),
        ServiceProxyModule,
        RootRoutingModule,
        RouterOutlet,
    ],
    declarations: [AppComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AbpHttpInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (appInitializer: AppInitializer) => appInitializer.init(),
            deps: [AppInitializer],
            multi: true,
        },
        {
            provide: API_BASE_URL,
            useFactory: () => AppConsts.remoteServiceBaseUrl,
        },
        {
            provide: LOCALE_ID,
            useFactory: getCurrentLanguage,
        },


        AppSessionService,
        { provide: API_BASE_URL, useFactory: () => AppConsts.remoteServiceBaseUrl },
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: (appInitializer: AppInitializer) => appInitializer.init(),
        //     deps: [
        //         AppInitializer,
        //         AppSessionService,
        //         SessionServiceProxy,
        //         AbpMultiTenancyService
        //     ],
        //     multi: true,
        // },
        provideAnimations(),
        provideToastr(),
        provideHttpClient(),
        provideRouter(appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({scrollPositionRestoration: 'enabled'}),
        ),

        // Material Date Adapter
        {
            provide : DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide : MAT_DATE_FORMATS,
            useValue: {
                parse  : {
                    dateInput: 'D',
                },
                display: {
                    dateInput         : 'DDD',
                    monthYearLabel    : 'LLL yyyy',
                    dateA11yLabel     : 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },

        // Transloco Config
        provideTransloco({
            config: {
                availableLangs      : [
                    {
                        id   : 'en',
                        label: 'English',
                    },
                    {
                        id   : 'tr',
                        label: 'Turkish',
                    },
                ],
                defaultLang         : 'en',
                fallbackLang        : 'en',
                reRenderOnLangChange: true,
                prodMode            : true,
            },
            loader: TranslocoHttpLoader,
        }),
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide   : APP_INITIALIZER,
            useFactory: () =>
            {
                const translocoService = inject(TranslocoService);
                const defaultLang = translocoService.getDefaultLang();
                translocoService.setActiveLang(defaultLang);

                return () => firstValueFrom(translocoService.load(defaultLang));
            },
            multi     : true,
        },

        // Fuse
        provideAuth(),
        provideIcons(),
        provideFuse({
            mockApi: {
                delay   : 0,
                services: mockApiServices,
            },
            fuse   : {
                layout : 'classy',
                scheme : 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme  : 'theme-default',
                themes : [
                    {
                        id  : 'theme-default',
                        name: 'Default',
                    },
                    {
                        id  : 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id  : 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id  : 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id  : 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id  : 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),
    ],
    bootstrap: [AppComponent],
})
export class RootModule {}
