import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { AppRouteGuard } from '@shared/auth/auth-route-guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'dashboard'},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AppRouteGuard],
        canActivateChild: [AppRouteGuard],
        // canActivate: [AuthGuard],
        // canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes')}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes')},
        ]
    },

    // Admin routes
    {
        path: '',
        //canActivate: [AppRouteGuard],
        //canActivateChild: [AppRouteGuard],
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            // Dashboards
            {path: 'dashboard',
                children: [
                    {   path: '', loadChildren: () => import('app/modules/admin/project/project.routes' )},
                ]
            },
            {   path: 'example', loadChildren: () => import('app/modules/admin/example/example.routes') },
            {
                path: 'passengers',
                children: [
                    { path: 'info', loadChildren: () => import('app/modules/passenger-info/passenger-info.routes') },
                ]
            },
            {
                path: 'transactions',
                children: [
                    { path: 'bookings', loadChildren: () => import('app/modules/transactions/bookings/bookings.routes') },
                ]
            },
            {
                path: 'database',
                children: [
                    { path: 'passengers', loadChildren: () => import('app/modules/database/passengers/passengers.routes') },
                    { path: 'agents', loadChildren: () => import('app/modules/database/agents/agents.routes') },
                    { path: 'packages', loadChildren: () => import('app/modules/database/packages/packages.routes') },
                    { path: 'suppliers', loadChildren: () => import('app/modules/database/suppliers/suppliers.routes') },
                ]
            },
            {
                path: 'accounting',
                loadChildren: () => import('app/modules/accounting/accounting.routes') 
            },
            {
                path: 'reports',
                children: [
                    { path: 'audit-logs', loadChildren: () => import('app/modules/reports/audit-logs/audit-logs.routes') },
                ]
            },
            {
                path: 'administration',
                children: [
                    { path: 'app-settings', loadChildren: () => import('app/modules/management/settings/settings.routes') },
                    { path: 'users', loadChildren: () => import('app/modules/management/users/users.routes') },
                    { path: 'roles', loadChildren: () => import('app/modules/management/roles/roles.routes') },
                    { path: 'system-logs', loadChildren: () => import('app/modules/management/system-logs/system-logs.routes') },
                ]
            },

            // Pages
            {path: 'pages',
                children: [
                    // Settings
                    {path: 'settings', loadChildren: () => import('app/modules/pages/settings/settings.routes')},
                    {path: 'maintenance', loadChildren: () => import('app/modules/pages/maintenance/maintenance.routes')},
                ]
            },
        ]
    },

    {
        path: '',
        canActivate: [AppRouteGuard],
        canActivateChild: [AppRouteGuard],
        // canActivate: [AuthGuard],
        // canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: 'users', loadChildren: () => import('app/modules/admin/example/example.routes')},
            { path: 'roles', loadChildren: () => import('app/modules/admin/example/example.routes')},
        ]
    },

];
